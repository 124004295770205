// @flow
import {
  FETCH_DELIVERIES_REQUEST,
  FETCH_DELIVERIES_SUCCESS,
  type RequestDeliveries,
  type ReceiveDeliveries,
} from 'types/actions/Delivery';
import { type Delivery } from 'types/entities/Delivery';
import { type Warehouse } from 'types/entities/Warehouse';
import moment from 'moment';
import {
  fetchDeliveries as fetchDeliveriesFromApi,
  completeDelivery as completeDeliveryByApi,
} from 'services/deliveryApi';
import { type ThunkAction, type Dispatch } from 'types/actions';
import { addNotification } from 'components/notifications/actions';
import { getMatched } from 'components/shop/gift-label';

const requestDeliveries = (): RequestDeliveries => ({
  type: FETCH_DELIVERIES_REQUEST,
});

const receiveDeliveries = (deliveries: Array<Delivery> | Array<any> | null): ReceiveDeliveries => ({
  type: FETCH_DELIVERIES_SUCCESS,
  deliveries,
});

export const fetchDeliveries =
  (params?: { day: string }): ThunkAction =>
  (dispatch: Dispatch) => {
    dispatch(requestDeliveries());
    fetchDeliveriesFromApi(params).then(
      deliveries => dispatch(receiveDeliveries(deliveries)),
      () => {
        dispatch(addNotification('Неудачная попытка загрузки доставок'));
        dispatch(receiveDeliveries(null));
      },
    );
  };

export const completeDelivery =
  (params: { deliveryId: number }): ThunkAction =>
  (dispatch: Dispatch) => {
    return completeDeliveryByApi(params).then(
      data => data,
      () => {
        dispatch(addNotification('Неудачная попытка изменения статуса доставки'));
      },
    );
  };

export const groupByWarehouses = (
  delivery: Delivery,
): {
  [any]: {
    warehouse: Warehouse,
    lines: Array<any>,
  },
} => {
  const warehouses = {};
  if (delivery && delivery.orders) {
    delivery.orders.forEach(order =>
      order.plans.forEach(plan => {
        const warehouseId = plan.warehouse_donor.id;
        warehouses[warehouseId] = {
          warehouse: plan.warehouse_donor,
          lines: warehouses[warehouseId]
            ? [...warehouses[warehouseId].lines, ...plan.lines]
            : plan.lines,
          completed: Boolean(plan.completed_time),
          invoices: warehouses[warehouseId]
            ? [...warehouses[warehouseId].invoices, plan.invoice].filter(invoice => invoice)
            : [plan.invoice],
          shipment_place_pointers: warehouses[warehouseId]
            ? [
                ...warehouses[warehouseId].shipment_place_pointers,
                plan.shipment_place_pointers,
              ].filter(place => place)
            : [plan.shipment_place_pointer],
        };
      }),
    );
  }
  return warehouses;
};

export const getRouteListToCopy = (deliveries: Array<any> | null) => {
  if (!deliveries) return deliveries;

  const list = deliveries.map(item => {
    const warehouses = groupByWarehouses(item);
    const private_comment = item.orders[0] && JSON.parse(item.orders[0].private_comment);

    return {
      order_id: item.orders.map(order => order.id).join(', '),
      delivery_zone: `${(item.delivery_zone && item.delivery_zone.name) || ''} ${
        item.shipment_place_pointer || ''
      }`,
      invoice: item.orders.map(order => order.upp_invoice).join(', '),
      tai: Object.keys(warehouses).filter(key => key == 203).length ? 'Тайская лавка' : '',
      store49: Object.keys(warehouses).filter(key => key == 117).length ? 'Окатовая 12А' : '',
      hab82: Object.keys(warehouses).filter(key => key == 85).length ? 'Хабаровская 82' : '',
      pv100: Object.keys(warehouses).filter(key => key == 100).length
        ? 'Магазин №100 Пункт выдачи'
        : '',
      dixi: Object.keys(warehouses).filter(key => key == 190).length ? 'Dixi' : '',
      virei: Object.keys(warehouses).filter(key => key == 201).length ? 'Вирэй' : '',
      time: `${item.address.line},
      c ${moment(item.delivery_period.start).format('HH:mm')} до ${moment(
        item.delivery_period.end,
      ).format('HH:mm')}, ${moment(item.delivery_period.end).format('DD MMMM')}, 
      ${item.recipient_phone || item.user.phone}, ${item.recipient_name || item.user.full_name}, `,
      cost: item.total_cost,
      is_paid: `${item.is_paid ? 'ЗАКАЗ ОПЛАЧЕН</br>' : ''}${
        item.orders.map(order => order.comment).join(', ') || ''
      }`,
      comment: `${(private_comment && private_comment.private_comment) || ''}
      ${item.orders.map(order =>
        getMatched(order, { onlyFullMatch: true }).length > 0 ? '[Подарок x1]' : '',
      )}`,
    };
  });
  return list;
};

export const getRouteListTableToCopy = (deliveries: Array<any> | null) => {
  if (!deliveries) return deliveries;
  const content = deliveries.reduce((prev, curr) => {
    const warehouses = groupByWarehouses(curr);
    const private_comment = curr.orders[0] && JSON.parse(curr.orders[0].private_comment);
    return (
      prev +
      `
    <tr>
      <td>${curr.orders[0].id}</td>
      <td>
      ${(curr.delivery_zone && curr.delivery_zone.name) || ''} ${curr.shipment_place_pointer || ''}
      </td>
      <td>
      ${curr.orders[0].upp_invoice || ''}
      </td>
      <td>
      ${Object.keys(warehouses).filter(key => key == 2).length ? 'Авоська' : ''}
      </td>
      <td>
      ${Object.keys(warehouses).filter(key => key == 3).length ? 'Реми' : ''}
      </td>
      <td>
      ${Object.keys(warehouses).filter(key => key == 100).length ? 'Магазин №100 Пункт выдачи' : ''}
      </td>
      <td>
      ${Object.keys(warehouses).filter(key => key == 203).length ? 'Тайская лавка' : ''}
      </td>
            <td>
      ${Object.keys(warehouses).filter(key => key == 117).length ? 'Магазин №49' : ''}
      </td>
      <td>
      ${Object.keys(warehouses).filter(key => key == 85).length ? 'на Хабаровской' : ''}
      </td>
      <td>
      ${curr.address.line}, 
      c ${moment(curr.delivery_period.start).format('HH:mm')} до ${moment(
        curr.delivery_period.end,
      ).format('HH:mm')}, ${moment(curr.delivery_period.end).format('DD MMMM')}, 
      <b>${curr.recipient_phone || curr.user.phone}</b>, ${
        curr.recipient_name || curr.user.full_name
      }, 
      </td>
      <td>${curr.total_cost}</td>
      <td>${curr.is_paid ? 'ЗАКАЗ ОПЛАЧЕН</br>' : ''}${curr.orders[0].comment || ''}</td>
      <td>
      ${(private_comment && private_comment.private_comment) || ''}
      ${curr.orders.map(order =>
        getMatched(order, { onlyFullMatch: true }).length > 0 ? '[Подарок x1]' : '',
      )}
      </td>
    </tr>
    `
    );
  }, '');
  return `<table>${content}</table>`;
};

export const getListToCopy = (deliveries: Array<any> | null) => {
  if (!deliveries) return '';
  const content = deliveries.reduce((prev, curr) => {
    const warehouses = groupByWarehouses(curr);
    const private_comment = curr.orders[0] && JSON.parse(curr.orders[0].private_comment);
    return (
      prev +
      `
    <tr>
      <td>
      ${(curr.delivery_zone && curr.delivery_zone.name) || ''} ${curr.shipment_place_pointer || ''}
      </br>
      ${curr.orders[0].upp_invoice || ''}
      </td>
      <td>${curr.orders[0].id}</td>
      <td>
      ${curr.address.line}, 
      c ${moment(curr.delivery_period.start).format('HH:mm')} до ${moment(
        curr.delivery_period.end,
      ).format('HH:mm')}, ${moment(curr.delivery_period.end).format('DD MMMM')}, 
      ${curr.recipient_phone || curr.user.phone}, ${curr.recipient_name || curr.user.full_name}, 
      </td>
      <td>
      ${(private_comment && private_comment.private_comment) || ''}, 
      </td>
    </tr>
    <tr>
      <td></td>
      <td>сумма</td>
      <td>${curr.total_cost}</td>
      <td>
      ${curr.is_paid ? 'ЗАКАЗ ОПЛАЧЕН</br>' : ''}
      ${curr.orders[0].comment || ''}
      ${curr.orders.map(order =>
        getMatched(order, { onlyFullMatch: true }).length > 0 ? '[Подарок x1]' : '',
      )}
      </td>
    </tr>

    ${Object.keys(warehouses).reduce((prev, curr) => {
      return (
        prev +
        (warehouses[curr].warehouse.vendor_id && warehouses[curr].warehouse.vendor_id === '1'
          ? ''
          : `
      <tr>
        <td></td>
        <td></td>
        <td>${warehouses[curr].warehouse.name}</td>
        <td>
        ${warehouses[curr].lines.reduce((prev, curr) => {
          return (
            prev +
            `
          <span>
            ${curr.product.title} - ${curr.quantity} шт.
          </span>
          <br />
          `
          );
        }, '')}
        </td>
      </tr>
      `)
      );
    }, '')}
    <tr>
    </tr>
    `
    );
  }, '');

  return `<table>${content}</table>`;
};
